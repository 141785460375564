<template>
	<div class="container">
		<top-header></top-header>
		<div class="monitorinfo width-1300">
			<div class="Installationlist">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/testing' }">产品运行在线监测</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/monitor' }">产品磨损在线监测</el-breadcrumb-item>
					<el-breadcrumb-item>产品磨损监测详情</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="top">
					<div class="title">磨损监测列表</div>
					<div class="select-item">
						<!-- <el-select v-model="value" placeholder="矿机名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-select v-model="value" placeholder="磨机名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select> -->
					</div>
					<div class="search">
						<input type="" name="" id="" value="" placeholder="请输入关键字搜索" />
						<div class="btn">搜索</div>
					</div>
				</div>
				<!-- <div class="list">
					<div class="item" v-for="(item,index) in sensorList" :key="index"
						:class="activeid == item.id ? 'active' : ''" @click="switchList(item.id)">
						<span>{{item.name}}</span>
					</div>
				</div> -->
				<div class="top-nav">
					<!-- <div class="select-item">
						<el-select v-model="value" placeholder="矿机名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-select v-model="value" placeholder="磨机名称">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div> -->
					<div class="nav-item" v-for="(item,index) in sensorList" :key="index"
						:class="item.id == activeid ? 'active' : ''" @click="switchList(item.id)">
						<span>{{item.name}}</span>
					</div>
				</div>
				<div class="right-info">
					<iframe :src="sensorUrl" v-if="sensorUrl"></iframe>
					<div class="no-data" v-else>
						<span>暂无详情</span>
					</div>
				</div>
			</div>
			<!-- <div class="device">
				<iframe :src="sensorUrl" v-if="sensorUrl"></iframe>
				<div class="no-data" v-else>
					<span>暂无详情</span>
				</div>
			</div> -->
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	// const DEFAULT_DATA = {
	// 	xAxisData: ["重庆", "西安", "福州", "杭州", "长沙", "南昌"],
	// 	yAxisData: [43, 41.8, 41.7, 41.6, 40.6, 40.6],
	// };

	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {
				options:[],
				value:'',
				activeid: 1,
				sensorList: [],
				abrasionInfo: {},
				abrasion: [],
				sensorUrl:'',
			}
		},
		created() {
			// this.drawLine();
			this.getSensorlist();
		},
		methods: {
			getSensorlist() {
				this.$get('front/sensor/get_list', {
					mine_id: this.$route.query.id,
					page: 1,
					num: 999
				}).then(res => {
					if (res.data.status == 200) {
						this.activeid = res.data.data.data[0].id;
						this.sensorList = res.data.data.data;
						// console.log(res);
						this.getSensorinfo(res.data.data.data[0].id);
					}
				})
			},
			getSensorinfo(id) {
				this.$get('front/sensor/get_details', {
					id: id
				}).then(res => {
					// console.log(res);
					this.sensorUrl = res.data.data.domain + res.data.data.url;
					//console.log(this.sensorUrl);
				})
			},
			switchList(id) {
				this.activeid = id;
				this.getSensorinfo(id);
			},
			drawLine2() { // 基于准备好的dom，初始化echarts实例        //var bar_dv = document.getElementById('bar_dv');
				var bar_dv2;
				this.$nextTick(function() {
					bar_dv2 = this.$refs.charts2;
					if (bar_dv2) {
						// console.log('bar_dv不为空');
						let myChart = this.$echarts.init(bar_dv2)
						// 绘制图表 '火炉省会城市极端高温对比'
						myChart.setOption({
							tooltip: {
								trigger: 'axis'
							},
							legend: {
								data: ['前护板剩余厚度']
							},
							xAxis: {
								type: 'time',
								axisLine: {
									formatter: function(params) {
										var time = new Date(params);
										var y = time.getFullYear();
										var m = time.getMonth() + 1;
										var d = time.getDate();
										var h = time.getHours();
										var mm = time.getMinutes();
										var s = time.getSeconds();
										return (h < 10 ? '0' + h : h) + ':' + (mm < 10 ? '0' : mm) +
											':' + (s < 10 ? '0' : s) + '\n' + y + '-' + (m < 10 ? '0' :
												m) + '-' + (d < 10 ? '0' : d);
									}
								}
							},
							yAxis: {
								name: '前护板剩余厚度 - [mm]',
								nameLocation: 'center',
								nameRotate: '90',
								nameGap: 30,
								nameTextStyle: {
									color: "#000",
									fontSize: 14,
								},
							},
							dataZoom: [{
								type: 'slider', //图表下方的伸缩条
								show: true, //是否显示
								realtime: true, //拖动时，是否实时更新系列的视图
								start: 0, //伸缩条开始位置（1-100），可以随时更改
								end: 100, //伸缩条结束位置（1-100），可以随时更改
							}],
							series: [{
								name: '前护板剩余厚度',
								symbolSize: 10,
								data: this.abrasion,
								type: 'scatter',
								itemStyle: {
									color: 'rgb(6,0,100)'
								}
							}]
						});
						myChart.on('georoam', function(params) {
							//console.log(params);
						});
					} else {
						//console.log('bar_dv为空!');
					}
				})
			},
			drawLine() { // 基于准备好的dom，初始化echarts实例        //var bar_dv = document.getElementById('bar_dv');
				var bar_dv;
				this.$nextTick(function() {
					bar_dv = this.$refs.charts;
					if (bar_dv) {
						// console.log('bar_dv不为空');
						let myChart = this.$echarts.init(bar_dv)
						// 绘制图表 '火炉省会城市极端高温对比'          
						myChart.setOption({
							tooltip: {
								trigger: 'axis'
							},
							legend: {
								data: ['磨损率']
							},
							dataZoom: [{
								type: 'slider', //图表下方的伸缩条
								show: true, //是否显示
								realtime: true, //拖动时，是否实时更新系列的视图
								start: 0, //伸缩条开始位置（1-100），可以随时更改
								end: 100, //伸缩条结束位置（1-100），可以随时更改
							}],
							xAxis: {
								type: 'time',
								axisLine: {
									formatter: function(params) {
										var time = new Date(params);
										var y = time.getFullYear();
										var m = time.getMonth() + 1;
										var d = time.getDate();
										var h = time.getHours();
										var mm = time.getMinutes();
										var s = time.getSeconds();
										return (h < 10 ? '0' + h : h) + ':' + (mm < 10 ? '0' : mm) +
											':' + (s < 10 ? '0' : s) + '\n' + y + '-' + (m < 10 ? '0' :
												m) + '-' + (d < 10 ? '0' : d);
									}
								}
							},

							yAxis: {
								name: '磨损率 - [mm/天]',
								nameLocation: 'center',
								nameRotate: '90',
								nameGap: 30,
								nameTextStyle: {
									color: "#000",
									fontSize: 14,
								},
							},
							series: [{
								name:'磨损率',
								symbolSize: 10,
								data: [
									['2021-05-27 18:06:20','0'],
									['2021-05-27 18:07:20','0'],
									['2021-05-27 18:08:20','0'],
									['2021-05-27 18:09:20','0'],
									['2021-05-27 18:10:20','0'],
									['2021-05-27 18:11:20','0'],
									['2021-05-27 18:12:20','0'],
									['2021-05-27 18:13:20','0'],
									['2021-05-27 18:14:20','0'],
									['2021-05-27 18:15:20','0'],
									['2021-05-28 18:06:20','0'],
									['2021-05-27 18:06:20','0'],
									['2021-05-29 18:06:20','0'],
									['2021-05-30 18:06:20','0'],
									['2021-05-31 18:06:20','0']
								],
								type: 'scatter',
								itemStyle: {
									color: 'rgb(255,0,100)'
								}
							}]
						});
						// console.log("this.eventType:" + 'click');
						// myChart.on('click', function(params) {
						// 	window.open('https://www.baidu.com/s?wd=' + encodeURIComponent(params.name));
						// });
					} else {
						//console.log('bar_dv为空!');
					}
				})
			},
			// getDateList(count,long){
			// 	var time = new Date().setMinutes(0);
			// 	time = time - 24*60*60*1000;
			// 	var categoryData = [];
			// 	for(var i = 0;i <= count;i++){
			// 		categoryData.push(monent(time).format('YYYY-MM-DD'))
			// 		time += long;
			// 	}
			// 	return categoryData;
			// }
		}
	}
</script>

<style lang="scss">
	.monitorinfo {
		margin-top: 60px;
		min-height: 803px;
		.Installationlist {
			padding-top: 20px;
			.right-info {
				width: 72%;
				margin-left: 3%;
				display: inline-block;
				vertical-align: top;
				height: 70vh;
				background-color: #fff;
				text-align: center;
				margin-bottom: 30px;
				@media screen and(min-width:320px) and(max-width:767px) {
					width: 100%;
					margin-left: 0;
					margin-top: 20px;
					.mine-table {
						border: 0;
					}
				}
				iframe{
					border: 0;
					height: 100%;
					width: 100%;
				}
			}
			.top-nav {
				width: 25%;
				display: inline-block;
				vertical-align: top;
				@media screen and(min-width:320px) and(max-width:767px) {
					width: 100%;
					border: 1px solid #D9D9D9;
				}
				.select-item {
					margin-bottom: 20px;
			
					@media screen and(min-width:320px) and(max-width:767px) {
						display: none;
					}
			
					.el-select {
						width: 47.5%;
						margin-right: 5%;
			
						&:last-child {
							margin-right: 0;
						}
			
						.el-input {
							font-size: 14px;
						}
			
						.el-input__inner {}
					}
				}
			
				.nav-item {
					font-size: 14px;
					padding: 12px 15px;
					background-color: #EBEBEB;
					cursor: pointer;
					box-sizing: border-box;
					&:hover {
						color: #fff;
						background-color: #00337D;
					}
			
					@media screen and(min-width:320px) and (max-width:767px) {
						width: 100%;
						display: block;
						margin: 0 auto;
						padding:10px 15px;
						border-bottom: 1px solid #D9D9D9;
						background-color: #fff;
					}
				}
			
				.active {
					background: #00337D;
					color: #fff;
			
					@media screen and(min-width:320px) and (max-width:767px) {
						border-bottom: 1px solid #00337D;
						border-radius: 5px;
					}
				}
			}
			.top {
				margin: 30px 0;
				display: flex;
				justify-content: space-between;
			
				@media screen and(min-width:320px) and(max-width:767px) {
					display: block;
				}
			
				.title {
					font-size: 26px;
					letter-spacing: 2px;
			
					@media screen and(min-width:320px) and (max-width:767px) {
						display: none;
					}
				}
			
				.select-item {
					display: none;
			
					@media screen and(min-width:320px) and (max-width:767px) {
						display: block;
			
						.el-select {
							display: inline-block;
							vertical-align: top;
							width: 48%;
			
							&:nth-child(1) {
								margin-right: 3.5%;
							}
						}
					}
				}
			
				.search {
					@media screen and(min-width:320px) and(max-width:767px) {
						margin-top: 20px;
					}
					input {
						border: 0;
						outline: none;
						height: 40px;
						padding: 10px;
						box-sizing: border-box;
						color: #999;
						width: 380px;
						vertical-align: middle;
			
						@media screen and(min-width:320px) and(max-width:767px) {
							width: 75%;
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
					}
			
					.btn {
						color: #fff;
						background-color: #00337D;
						height: 40px;
						line-height: 40px;
						padding: 0 20px;
						display: inline-block;
						vertical-align: middle;
						box-sizing: border-box;
			
						@media screen and(min-width:320px) and(max-width:767px) {
							width: 25%;
							text-align: center;
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
						}
					}
				}
			}
		}
	}

</style>
